"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const game_material_click_1 = require("@src/shared/game-material-click");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const defaultFaces = [4, 6, 8, 10, 12, 20, 100].map((item) => {
    return {
        faces: item,
        count: 0
    };
});
function randChoice(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}
const rangeItems = function (size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
};
class DicerollerCtrl {
    constructor($scope, $timeout, ModalServiceFactory, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.faces = JSON.parse(localStorage.getItem('faces') || 'null') || ng.copy(defaultFaces);
        this.emptyDice = true;
        this.isDice = false;
        this.lastRoll = JSON.parse(localStorage.getItem('lastRoll') || '[]');
        this.history = JSON.parse(localStorage.getItem('history') || '[]');
        this.duration = 2000;
        this.$scope.$watch('$ctrl.faces', (faces) => {
            localStorage.setItem('faces', JSON.stringify(faces));
            this.emptyDice = true;
            for (let item of faces) {
                if (item.count > 0) {
                    this.emptyDice = false;
                    break;
                }
            }
        }, true);
        this.$scope.$watch('$ctrl.lastRoll', (lastRoll) => {
            localStorage.setItem('lastRoll', JSON.stringify(lastRoll));
        }, true);
    }
    $onInit() {
    }
    roll() {
        if (this.diceForm) {
            if (this.diceForm.$valid) {
                const dices = this.faces.filter((item) => {
                    return item.count > 0;
                }).map((item) => {
                    this.isDice = true;
                    let delays = [];
                    let stepDuration = 0;
                    while (stepDuration < this.duration) {
                        const value = Math.floor(50 + (Math.random() * 20));
                        delays.push(value);
                        stepDuration += value;
                    }
                    delays[delays.length - 1] = delays[delays.length - 1] - (stepDuration - this.duration);
                    return (0, rxjs_1.of)(...delays.sort((a, b) => a - b)).pipe((0, operators_1.concatMap)((ms) => {
                        return (0, rxjs_1.of)(item).pipe((0, operators_1.delay)(ms), (0, operators_1.map)((dice) => {
                            let count = dice.count;
                            let results = [];
                            while (count > 0) {
                                results.push(randChoice(rangeItems(dice.faces, 1)));
                                count -= 1;
                            }
                            return {
                                results: results,
                                faces: dice.faces,
                                total: results.reduce((a, b) => a + b, 0)
                            };
                        }));
                    }));
                });
                if (dices.length) {
                    this.play();
                }
                (0, rxjs_1.combineLatest)(dices).pipe((0, operators_1.tap)((i) => {
                    this.$scope.$apply(() => {
                        let exists = false;
                        for (let y of i) {
                            let exists = false;
                            for (let result of this.lastRoll) {
                                if (result.faces == y.faces) {
                                    result.results = y.results;
                                    result.total = y.total;
                                    exists = true;
                                    break;
                                }
                            }
                            if (!exists) {
                                this.lastRoll.push(y);
                                exists = true;
                            }
                        }
                        if (!exists) {
                            for (let [index, result] of this.lastRoll.entries()) {
                                let exists = false;
                                for (let y of i) {
                                    if (result.faces == y.faces) {
                                        exists = true;
                                        break;
                                    }
                                }
                                if (!exists)
                                    this.lastRoll.splice(index, 1);
                            }
                        }
                        this.lastRoll = this.lastRoll.sort((a, b) => a.faces - b.faces);
                    });
                }), (0, operators_1.finalize)(() => {
                    this.$scope.$apply(() => {
                        this.isDice = false;
                        this.history.unshift(ng.copy(this.lastRoll));
                        localStorage.setItem('history', JSON.stringify(this.history));
                        this.SoundService.stop_all();
                    });
                })).subscribe();
            }
        }
    }
    reset() {
        if (this.diceForm) {
            this.diceForm.$setPristine();
            this.diceForm.$setUntouched();
        }
        this.faces = ng.copy(defaultFaces);
        this.lastRoll = [];
    }
    showHistory() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.history.length)
                return Promise.resolve();
            const result = yield this.ModalServiceFactory.open({
                id: 'diceroller-history',
                component: "alert-comp",
                scope: this.$scope,
                template: require('./showHistory.ng.html'),
                strategy: "override",
                extraContext: {
                    data: 'test',
                    history: this.history,
                    sum: function (historyItem) {
                        return historyItem.map((item) => item.total).reduce((a, b) => a + b, 0);
                    },
                    fillForm: function (historyItem) {
                        var _a;
                        this.faces.map((item) => item.count = 0);
                        for (let item of historyItem) {
                            for (let face of this.faces) {
                                if (face.faces == item.faces) {
                                    face.count = item.results.length;
                                }
                            }
                            (_a = this.diceForm) === null || _a === void 0 ? void 0 : _a.$setDirty();
                            this.ModalServiceFactory.close('diceroller-history', 'roll');
                        }
                    }.bind(this)
                }
            });
            if (result == 'clear') {
                localStorage.removeItem('history');
                this.history = [];
            }
            if (result == 'roll') {
                this.roll();
            }
        });
    }
    sub(index, min, step = 1) {
        this.faces[index].count -= (this.faces[index].count > min ? step : 0);
    }
    add(index, max, step = 1) {
        this.faces[index].count += (this.faces[index].count < max ? step : 0);
    }
    play() {
        // return
        this.SoundService.stop_all();
        this.$timeout(() => {
            this.SoundService.play('~Diceroll', false, 0.75);
        }, 100);
    }
}
DicerollerCtrl.$inject = ['$scope', '$timeout', 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.directive('gameMaterialClick', game_material_click_1.gameMaterialClick);
appModule.component('gameDiceroller', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: DicerollerCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'SoundServiceProvider', 'ConfigServiceProvider',
    (WsServiceProvider, SoundServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('diceroller/');
        SoundServiceProvider.setSound({
            '~DiceActive': require('./sounds/dice-active.mp3').default,
            '~DiceReveal': require('./sounds/dice-reveal.mp3').default,
            '~Diceroll': require('./sounds/diceroll.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            sound_effects: true,
        });
    }]);
